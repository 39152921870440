import React, { useCallback, useState } from 'react'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { Link, TextField } from '@mui/material'
import Button from '@mui/material/Button'
import { useForm } from 'react-hook-form'

interface Props {}

type FormValues = {
  fullName: string
  email: string
  message: string
}

const ContactUs: React.FC<Props> = () => {
  const [submitted, setSubmitted] = useState<boolean>(false)

  const { register, trigger, getValues, formState: { errors } } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      fullName: '',
      email: '',
      message: '',
    },
  })

  const submitContactForm = useCallback(async () => {
    await trigger()
    if (Object.keys(errors).length > 0) {
      return
    }

    const emailMessage = getValues()
    const { fullName, email, message } = emailMessage
    const postBody = { fullName, email, message }

    const r = await fetch(`${process.env.REACT_APP_BACKEND_API}/contact`, {
      method: 'POST',
      body: JSON.stringify(postBody),
      headers: { 'Content-Type': 'application/json' },
    })

    console.log(r)

    if (r.ok) {
      setSubmitted(true)
    }

  }, [errors, getValues, trigger])

  if (submitted) {
    return  (
        <Box maxWidth={800} sx={{ margin: '0px auto;' }}>
          <Typography variant={'h2'} mb={2} sx={{ textAlign: 'center' }}>Thank you for your email. I'll be in touch soon.</Typography>
        </Box>
    )
  }

  return (
      <Container maxWidth="md">
        <Typography variant={'h2'} mb={2} sx={{ textAlign: 'center' }}>Let's get started</Typography>

        <Box component="form" noValidate sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                  fullWidth
                  id="fullName"
                  label="Full Name*"
                  {...register('fullName', { required: true })}
              />
              {errors?.fullName && <Typography color={'red'} fontSize={'small'} variant={'body1'}>Full Name is Required</Typography>}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                  fullWidth
                  id="email"
                  label="Email Address*"
                  {...register('email', { required: true,
                    pattern: {
                      value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                      message: 'is not a valid email address format',
                    } })}
              />
              {errors?.email?.type === 'required' && <Typography color={'red'} fontSize={'small'} variant={'body1'}>Email is Required</Typography>}
              {errors?.email?.type === 'pattern' && <Typography color={'red'} fontSize={'small'} variant={'body1'}>Email must be valid name@domain.com</Typography>}
            </Grid>
            <Grid item xs={12}>
              <TextField
                  multiline={true}
                  fullWidth
                  rows={4}
                  id="message"
                  label="Your Message*"
                  {...register('message',
                    {
                      required: true,
                    })}
              />
              {errors?.message && <Typography color={'red'} fontSize={'small'} variant={'body1'}>Your message is Required</Typography>}

            </Grid>
          </Grid>
          <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={submitContactForm}
          >
            Send
          </Button>
          <Box my={2}>
            <Typography>Not a fan of forms? Email us directly at <Link href={'mailto:nick@nlc.consulting'} sx={{ color: 'primay' }}>nick@nlc.consulting</Link></Typography>
          </Box>
        </Box>
      </Container>
  )
}

export default ContactUs
