import React from 'react'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import LandingPage from './pages/LandingPage'

function App() {
  return (
    <div className="app">
      <LandingPage/>
    </div>
  )
}

export default App
