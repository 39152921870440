import React, { useMemo } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import StarIcon from '@mui/icons-material/StarBorder'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import GlobalStyles from '@mui/material/GlobalStyles'
import Container from '@mui/material/Container'
import { Avatar, Divider, Link, useMediaQuery } from '@mui/material'
import ContactUs from '../common/ContactUs'


interface Props {}

const LandingPage: React.FC<Props> = () => {
  const isDesktop = useMediaQuery('(min-width:900px)')

    const services = [
        {
            "title": ".NET Core Development",
            "imgURL": "/images/net-core.png",
            "description": [
                "Web / Console / WinForms Applications",
                "REST APIs with Entity Framework",
                "Blazor & MVC Frameworks",
                "SQL Server & Azure Integration"
            ],
            "buttonText": "Contact us",
            "buttonVariant": "contained"
        },
        {
            "title": "JavaScript Development",
            "imgURL": "/images/javascript.png",
            "description": [
                "Front-End: React, Angular, Vue",
                "Back-End: Node APIs with Express",
                "Databases: MySQL, PostgreSQL, NoSQL",
                "CSS Frameworks: Material UI, Tailwind, Bootstrap"
            ],
            "buttonText": "Contact us",
            "buttonVariant": "contained"
        },
        {
            "title": "AI & Machine Learning",
            "imgURL": "/images/chat-gpt.png",
            "description": [
                "OpenAI Integrations: Chat GPT, Whisper",
                "Google Machine Learning Tools",
                "Ethical Web Scraping & Data Collection",
                "Automation of Repetitive Tasks"
            ],
            "buttonText": "Contact us",
            "buttonVariant": "contained"
        }
    ]


  const dynamicHeight = useMemo(() => {
    let result = 250

    if (isDesktop) {
      result = 600
    }

    return result
  }, [isDesktop])

  return (
      <>
          <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }}/>
          <CssBaseline/>
          <AppBar
              position="static"
              color="default"
              elevation={0}
              sx={{
                borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                backgroundColor: '#13213c',
              }}
          >
              <Toolbar>
                  <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                      <Link href={'/'}>
                          <img src={'/images/nlc-logo.png'} height={85} alt='NLC Consulting, LLC'/>
                      </Link>
                  </Box>
              </Toolbar>
          </AppBar>
          {/* Hero unit */}
          <Container disableGutters maxWidth="md" component="main" sx={{ pt: 8, pb: 6 }}>
              <Typography
                  component="h1"
                  variant="h2"
                  align="center"
                  color="text.primary"
                  gutterBottom
              >
                  Software Engineering & Technology Consulting
              </Typography>
              <Typography variant="h5" align="center" color="text.secondary" component="p">
                  {/* eslint-disable-next-line max-len */}
                  NLC Consulting offers affordable software development and consulting services, specializing in .NET and JavaScript. We build business applications, as well as custom APIs and integrations across various industries. We integrate AI and machine learning to enhance business processes and drive innovation.
              </Typography>
          </Container>
          {/* End hero unit */}
          <Container maxWidth="lg" component="main">
              <Grid container spacing={5}>
                  {services.map((service) => (
                      <Grid
                          item
                          key={service.title}
                          xs={12}
                          sm={service.title === 'Enterprise' ? 12 : 6}
                          md={4}
                      >
                          <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                              }}
                          >
                              <Card sx={{ flex: 1 }}>
                                  <CardHeader
                                      title={service.title}
                                      titleTypographyProps={{ align: 'center' }}
                                      action={service.title === 'Pro' ? <StarIcon/> : null}
                                      subheaderTypographyProps={{
                                        align: 'center',
                                      }}
                                      sx={{
                                        color: '#000',
                                        fontWeight: 'bold',
                                        backgroundColor: (theme) => '#11d0ed',
                                      }}
                                  />
                                  <CardContent>
                                      <Box
                                          sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'baseline',
                                            mb: 2,
                                          }}
                                      >
                                          <Box>
                                              <img src={service.imgURL} height={75} alt={'Service Type Logo'}/>
                                          </Box>
                                      </Box>
                                      <ul>
                                          {service.description.map((line) => (
                                              <Typography
                                                  component="li"
                                                  variant="subtitle1"
                                                  align="center"
                                                  key={line}
                                              >
                                                  {line}
                                              </Typography>
                                          ))}
                                      </ul>
                                  </CardContent>
                              </Card>
                          </Box>
                      </Grid>
                  ))}
              </Grid>
          </Container>

          {/*<Container maxWidth="md" component="main">*/}

          {/*<Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>*/}
          {/*    <img src={'/images/pd-logo.jpg'}*/}
          {/*         alt="PhilthyDimes Logo"*/}
          {/*         style={{ width: '40px', height: 'auto', marginRight: '10px' }}*/}
          {/*    />*/}
          {/*    <p>*/}
          {/*        Visit our flagship project, <a href="https://www.philthydimes.com" target="_blank"*/}
          {/*                                       rel="noopener noreferrer" style={{color: 'orange', fontWeight: 'bold', textDecoration: 'none'}}>PhilthyDimes</a>, developed by our founder*/}
          {/*        Nick. This project applies data collection and AI to predict outcomes of basketball games and provide*/}
          {/*        condensed game recaps.*/}
          {/*    </p>*/}
          {/*</Box>*/}
          {/*</Container>*/}

          <Box sx={{ margin: '0 auto', maxWidth: 800 }}>
              <Divider sx={{ marginTop:2, marginBottom: 6 }}/>
          </Box>

          <Container maxWidth="md">
              <Box>
                  <Typography variant={'h2'} mb={2} sx={{ textAlign: 'center' }}>My name is Nick</Typography>
                  <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                              <Avatar alt="Nick" src="/images/nick_chiafullo.png"
                                      sx={{ width: 300, height: 300, marginBottom: 2 }}/>
                              <Typography>Nick Chiafullo</Typography>
                              <Typography>President, NLC Consulting</Typography>
                              <Typography><Link href={'mailto:nick@nlc.consulting'}
                                                sx={{ color: 'primay' }}>nick@nlc.consulting</Link></Typography>
                              <Typography>732.233.1566</Typography>
                          </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }} height={'100%'}>
                              <Typography variant={'body1'} mb={3} fontSize={'large'}>
                                  {/* eslint-disable-next-line max-len */}
                                  I am a Software Architect and technology consultant with over 15 years of experience designing applications and integrating systems and APIs into business applications across various sectors, including pharmaceutical, laboratory management, mass tort, shipping and logistics, e-commerce, and the security space. I specialize in .NET/C#, SQL Server, JavaScript (React/Node), and have a deep understanding of all aspects of the business application lifecycle including project management, analysis, design, and programming with automated testing and deployments. I have excellent communication skills and always deliver.
                              </Typography>
                          </Box>
                      </Grid>
                  </Grid>
              </Box>
          </Container>

          <Box sx={{ margin: '0 auto', maxWidth: 800 }}>
              <Divider sx={{ marginTop: 10, marginBottom: 6 }}/>
          </Box>

          <ContactUs/>

          <div style={{
            marginTop: 100,
            backgroundImage: 'url("/images/nlc-bottom.jpg")',
            height: dynamicHeight,
            backgroundColor: '#000',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50% 40%',
          }}></div>
      </>
  )
}

export default LandingPage
